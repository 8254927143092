import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import loginImg from '../../../assets/images/rubblLogo.png'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { userLogin } from 'src/actions/AuthActions'

const Login = (props) => {
  const [input, setInput] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const handelChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value })
  }

  const handelSubmit = async (e) => {
    e.preventDefault()
    const { Login } = props
    if (input) {
      if (!input.username) {
       toast.error('Please Enter UserName')
      }
      else if (!input.password) {
        toast.error('Please Enter Password')
        // navigate('/dashboard')
      }
      else {
        let paylaod = {
          username: input.username,
          password: input.password
        }
        dispatch(userLogin(paylaod)).unwrap()
      .then(res => {
        if (res?.data?.user?.role?.slug === 'admin') {
          toast.success("Login successful")
          navigate('/dashboard')
        }
        else{
          alert("Invalid Username and Password")
        }
      })
      }
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        name="username"
                        placeholder="Username/Email"
                        autoComplete="username"
                        onChange={handelChange}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        onChange={handelChange}
                      />
                    </CInputGroup>
                    <CRow>
                    <Link to="/forgotPassword">Forgot password</Link>
                    </CRow>
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          color="primary"
                          className="px-4"
                          style={{ backgroundColor: 'rgb(0,126,229)', border: 'none' }}
                          onClick={handelSubmit}
                        >
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white py-5"
                style={{ width: '44%', backgroundColor: 'rgb(0,126,229)' }}
              >
                <CCardBody className="text-center d-flex justify-content-center align-items-center">
                  <div style={{ flexDirection: 'column', display: 'flex' }}>
                    <img
                      style={{ width: '250px', height: '70px' }}
                      src={loginImg}
                      alt={'login Image'}
                    />
                    <span
                      style={{
                        fontSize: '17px',
                        fontFamily: 'Montserrat',
                        marginTop: '20px',
                      }}
                    >
                      Admin panel
                    </span>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login;
