export const GET_SUPPLIER_CONSTANT = "get_supplier_constant";
export const ADD_DELIVERY_PRICE = "add_delivery_price";
export const UPDATE_DELIVERY_PRICE = "update_delivery_price";
export const GET_SUBURB_LISTS = "get_suburb_lists";
export const DELETE_SUBURB_LISTS = "delete_suburb_lists";
export const ADD_SUBURB_LISTS = "add_suburb_lists";
export const UPDATE_SUBURB_LISTS = "update_suburb_lists";
export const DELETE_DELIVERY_ITEM_WITH_ID = "delete_delivery_item_with_id";
export const GET_RANGES_LISTS = "get_ranges_list";
export const ADD_RANGES_LISTS = "add_ranges_list";
export const SET_RANGES_AREA = "set_ranges_area";
export const ADD_PROMOTIONS = "add_promotion"
export const GET_PROMOTIONS = "get_promotion"
