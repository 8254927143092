import API from 'src/services'
import { toast } from 'react-toastify'
import { decodeToken } from 'react-jwt'
import { useNavigate } from 'react-router-dom'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    LOGIN,
    ACCOUNTLIST,
    SUPPLIER_LIST,
    TRANSPORTER_LIST,
    ORDERS_LIST,
    EMAIL_TEMPLATE,
    MATERIAL_LIST,
    NOTIFICATION_TEMPLATE,
    INVOICE_LIST,
    ADD_CONTRACTOR,
    UPLOAD_IMAGE,
    ADD_SUPPLIER,
    ADD_TRANSPORTER,
    UPDATE_CONTRACTOR,
    UPDATE_SUPPLIER,
    UPDATE_TRANSPORTER,
    ACTIVE_SITES,
    CONTRACTOR_ORDER_LIST,
    CONTRACTOR_INVOICE_LIST,
    SUPPLIER_ORDER_LIST,
    SUPPLIER_ORDER_DETAIL,
    SUPPLIER_MATERIAL_LIST,
    TRANSPORTER_ORDER_LIST,
    DRIVERS_LIST,
    VEHICLE_LIST,
    HELP_CENTER,
    DELETE_HELP_CENTER,
    MATERIAL_CATEGORY,
    ADD_MATERIAL,
    UPDATE_MATERIAL,
    VEHICLE_TYPE,
    TIERED_PRICING,
    DASHBOARD_COUNT,
    CHART_DATA,
    ADD_EMAIL_TEMPLATE,
    UPDATE_EMAIL_TEMPLATE,
    EDIT_NOTIFICATION_TEMPLATE,
    UPDATE_INVOICE,
    SEND_RECEIPT,
    EDIT_SUPPLIER_MATERIAL,
    DELETE_MATERIAL,
    ORDER_CANCEL,
    FORGOT_PASSWORD,
    CANCEL_ORDER,
    GET_TERMS_CONDITIONS,
    UPDATE_TERMS_CONDITIONS,
    GET_PRIVACY_POLICY,
    UPDATE_PRIVACY_POLICY,
    GET_ABOUT,
    UPDATE_ABOUT,
    INVOICE_DOWNLOAD,
    GET_TIERED_PRICING,
    SUPPLIER_PAYMENTS,
    UPDATE_SUPPLIER_PAYMENTS,
    TRANSPORTER_PAYMENTS,
    UPDATE_TRANSPORTER_PAYMENTS,
    GET_LOAD_ASSUMPTIONS,
    CHANGE_PASSWORD,
    TOGGLE_SIDEBAR,
    CREATE_ABOUT,
    UPDATE_RUBBISH_ABOUT,
    GET_RUBBL_DATA,
    PRODUCTS_LIST,
    CREATE_PRODUCTS,
    DELETE_PRODUCTS,
    TOGGLE_ADD_EDIT_PRODUCTS,
    UPDATE_PRODUCTS,
    SEARCH_PRODUCTS
} from 'src/types'

export const userLogin = createAsyncThunk(
  LOGIN,
  async (data) => {
      // console.log("data",data); 
      const response = await API.login(data)
      if (response && response.status === 200) {
            const tokenDetail = decodeToken(response.data.data.accessToken)
            // console.log('tokenDetail', tokenDetail)
            if (response.data.data.user.role.slug === 'admin') {
              localStorage.setItem('token', response.data.data.accessToken)
              // toast.success('Login Successfully')
              // return {
              //   type: LOGIN,
              //   payload: response,
              // }
            } else {
              toast.error('Invalid username or password')
            }
          } else {
            if (response && response.data && response.data.error && response.data.error.message) {
              toast.error(response.data.error.message)
            }
          }
      // setUserData(response?.data?.data)
      return response.data
  }
)

export const accountList = createAsyncThunk(
  ACCOUNTLIST,
  async (data) => {
      const response = await API.accountList(data)
      return response.data
  }
)

export const supplierList = createAsyncThunk(
  SUPPLIER_LIST,
  async (data) => {
      const response = await API.accountList(data)
      return response.data
  }
)

export const transporterList = createAsyncThunk(
  TRANSPORTER_LIST,
  async (data) => {
      const response = await API.accountList(data)
      return response.data
  }
)

export const ordersList = createAsyncThunk(
  ORDERS_LIST,
  async (data) => {
      const response = await API.ordersList(data)
      return response.data
  }
)

export const emailTemplate = createAsyncThunk(
  EMAIL_TEMPLATE,
  async (data) => {
      const response = await API.emailTemplateList(data)
      return response.data
  }
)

export const materialList = createAsyncThunk(
  MATERIAL_LIST,
  async (data) => {
      const response = await API.materialList(data)
      return response.data
  }
)

export const notificationTemplate = createAsyncThunk(
  NOTIFICATION_TEMPLATE,
  async (data) => {
      const response = await API.notificationTemplate(data)
      return response.data
  }
)

export const invoiceList = createAsyncThunk(
  INVOICE_LIST,
  async (data) => {
      const response = await API.invoiceList(data)
      return response.data
  }
)

export const addContractor = createAsyncThunk(
  ADD_CONTRACTOR,
  async (data) => {
      const response = await API.addContractor(data)
      return response.data
  }
)

export const addSupplier = createAsyncThunk(
  ADD_SUPPLIER,
  async (data) => {
      const response = await API.addSupplier(data)
      // console.log("response999",response);
      return response.data
  }
)

export const addTransporter = createAsyncThunk(
  ADD_TRANSPORTER,
  async (data) => {
      const response = await API.addTransporter(data)
      return response.data
  }
)

export const uploadImage = createAsyncThunk(
  UPLOAD_IMAGE,
  async (data) => {
      let data1 = new FormData();
      data1.append('image', data,data?.name);
      const response = await API.imageUpload(data1)
      return response.data
  }
)

export const updateContractor = createAsyncThunk(
  UPDATE_CONTRACTOR,
  async (data) => {
      const response = await API.updateContractor(data)
      return response.data
  }
)

export const updateSupplier = createAsyncThunk(
  UPDATE_SUPPLIER,
  async (data) => {
      const response = await API.updateSupplier(data)
      return response.data
  }
)

export const updateTransporter = createAsyncThunk(
  UPDATE_TRANSPORTER,
  async (data) => {
      const response = await API.updateTransporter(data)
      return response.data
  }
)

export const activeSites = createAsyncThunk(
  ACTIVE_SITES,
  async (data) => {
      const response = await API.activeSites(data)
      return response.data
  }
)

export const contractorOrderList = createAsyncThunk(
  CONTRACTOR_ORDER_LIST,
  async (data) => {
      const response = await API.contractorOrderList(data)
      return response.data
  }
)

export const contractorInvoiceList = createAsyncThunk(
  CONTRACTOR_INVOICE_LIST,
  async (data) => {
      const response = await API.contractorInvoiceList(data)
      return response.data
  }
)

export const supplierOrderList = createAsyncThunk(
  SUPPLIER_ORDER_LIST,
  async (data) => {
      const response = await API.supplierOrderList(data)
      return response.data
  }
)

export const supplierOrderDetail = createAsyncThunk(
  SUPPLIER_ORDER_DETAIL,
  async (data) => {
      const response = await API.supplierOrderDetail(data)
      return response.data
  }
)

export const supplierMaterialList = createAsyncThunk(
  SUPPLIER_MATERIAL_LIST,
  async (data) => {
      const response = await API.supplierMaterialList(data)
      return response.data
  }
)

export const transporterOrderList = createAsyncThunk(
  TRANSPORTER_ORDER_LIST,
  async (data) => {
      const response = await API.transporterOrderList(data)
      return response.data
  }
)

export const driversList = createAsyncThunk(
  DRIVERS_LIST,
  async (data) => {
      const response = await API.driversList(data)
      return response.data
  }
)

export const vehicleList = createAsyncThunk(
  VEHICLE_LIST,
  async (data) => {
      const response = await API.vehicleList(data)
      return response.data
  }
)

export const helpCenter = createAsyncThunk(
  HELP_CENTER,
  async (data) => {
      const response = await API.helpCenter(data)
      return response.data
  }
)

export const deleteHelpCenter = createAsyncThunk(
  DELETE_HELP_CENTER,
  async (data) => {
      const response = await API.deleteHelpCenter(data)
      return response.data
  }
)

export const materialCategory = createAsyncThunk(
  MATERIAL_CATEGORY,
  async (data) => {
      const response = await API.materialCategory(data)
      return response.data
  }
)

export const addMaterial = createAsyncThunk(
  ADD_MATERIAL,
  async (data) => {
      const response = await API.addMaterial(data)
      return response.data
  }
)

export const updateMaterial = createAsyncThunk(
  UPDATE_MATERIAL,
  async (data) => {
      const response = await API.updateMaterial(data)
      return response.data
  }
)

export const vehicleType = createAsyncThunk(
  VEHICLE_TYPE,
  async (data) => {
      const response = await API.vehicleType(data)
      return response.data
  }
)

export const tieredPricing = createAsyncThunk(
  TIERED_PRICING,
  async (data) => {
      const response = await API.tieredPricing(data)
      return response.data
  }
)

export const dashboardCount = createAsyncThunk(
  DASHBOARD_COUNT,
  async (data) => {
      const response = await API.dashboardCount(data)
      return response.data
  }
)

export const chartData = createAsyncThunk(
  CHART_DATA,
  async (data) => {
      const response = await API.chartData(data)
      return response.data
  }
)

export const addEmailTemplate = createAsyncThunk(
  ADD_EMAIL_TEMPLATE,
  async (data) => {
      const response = await API.addEmailTemplate(data)
      return response.data
  }
)

export const updateEmailTemplate = createAsyncThunk(
  UPDATE_EMAIL_TEMPLATE,
  async (data) => {
      const response = await API.updateEmailTemplate(data)
      return response.data
  }
)

export const updateNotificationTemplate = createAsyncThunk(
  EDIT_NOTIFICATION_TEMPLATE,
  async (data) => {
      const response = await API.updateNotificationTemplate(data)
      return response.data
  }
)

export const updateInvoice = createAsyncThunk(
  UPDATE_INVOICE,
  async (data) => {
      const response = await API.updateInvoice(data)
      return response.data
  }
)

export const sendReceipt = createAsyncThunk(
  SEND_RECEIPT,
  async (data) => {
      const response = await API.sendReceipt(data)
      return response.data
  }
)

export const editSupplierMaterial = createAsyncThunk(
  EDIT_SUPPLIER_MATERIAL,
  async (data) => {
      const response = await API.editSupplierMaterial(data)
      return response.data
  }
)

export const deleteMaterial = createAsyncThunk(
  DELETE_MATERIAL,
  async (data) => {
      const response = await API.deleteMaterial(data)
      return response.data
  }
)

export const orderCancel = createAsyncThunk(
  ORDER_CANCEL,
  async (data) => {
      const response = await API.orderCancel(data)
      return response.data
  }
)

export const forgotPassword = createAsyncThunk(
  FORGOT_PASSWORD,
  async (data) => {
      const response = await API.forgotPassword(data)
      return response.data
  }
)



export const getTermsConditions = createAsyncThunk(
  GET_TERMS_CONDITIONS,
  async (data) => {
      const response = await API.getTermsAndCondition(data)
      return response.data
  }
)

export const updateTermsConditions = createAsyncThunk(
  UPDATE_TERMS_CONDITIONS,
  async (data) => {
      const response = await API.updateTermsAndCondition(data)
      return response.data
  }
)



export const getPrivacyPolicy = createAsyncThunk(
  GET_PRIVACY_POLICY,
  async (data) => {
      const response = await API.getPrivacyPolicy(data)
      return response.data
  }
)

export const updatePrivacyPolicy = createAsyncThunk(
  UPDATE_PRIVACY_POLICY,
  async (data) => {
      const response = await API.updatePrivacyPolicy(data)
      return response.data
  }
)



export const getAbout = createAsyncThunk(
  GET_ABOUT,
  async (data) => {
      const response = await API.getAbout(data)
      return response.data
  }
)

export const updateAbout = createAsyncThunk(
  UPDATE_ABOUT,
  async (data) => {
      const response = await API.updateAbout(data)
      return response.data
  }
)
export const updateRubbishAbout = createAsyncThunk(
    UPDATE_RUBBISH_ABOUT,
  async (data) => {
      const response = await API.updateRubbishAbout(data)
      return response.data
  }
)

export const invoiceDownload = createAsyncThunk(
  INVOICE_DOWNLOAD,
  async (data) => {
      const response = await API.invoiceDownload(data)
      return response
  }
)

export const getTieredPricing = createAsyncThunk(
  GET_TIERED_PRICING,
  async (data) => {
      const response = await API.getTieredPricing(data)
      return response.data
  }
)

export const supplierPayments = createAsyncThunk(
  SUPPLIER_PAYMENTS,
  async (data) => {
      const response = await API.supplierPayments(data)
      return response.data
  }
)

export const updateSupplierPayments = createAsyncThunk(
  UPDATE_SUPPLIER_PAYMENTS,
  async (data) => {
      const response = await API.updateSupplierPayments(data)
      return response.data
  }
)

export const transporterPayments = createAsyncThunk(
  TRANSPORTER_PAYMENTS,
  async (data) => {
      const response = await API.transporterPayments(data)
      return response.data
  }
)

export const updateTransporterPayments = createAsyncThunk(
  UPDATE_TRANSPORTER_PAYMENTS,
  async (data) => {
      const response = await API.updateTransporterPayments(data)
      return response.data
  }
)

export const getLoadAssumptions = createAsyncThunk(
  GET_LOAD_ASSUMPTIONS,
  async (data) => {
      const response = await API.getLoadAssumptions(data)
      return response.data
  }
)

export const changePassword = createAsyncThunk(
  CHANGE_PASSWORD,
  async (data) => {
      const response = await API.changePassword(data)
      return response.data
  }
)

export const cancelOrder = createAsyncThunk(
  CANCEL_ORDER,
  async (data) => {
      const response = await API.cancelOrder(data)
      return response.data
  }
)


export const ToggleSidebar = createAsyncThunk(
    TOGGLE_SIDEBAR,
    async (data) => {
        return {}
    }
)

export const createAbout = createAsyncThunk(
    CREATE_ABOUT,
    async (data) => {
        const response = await API.createAbout(data)
        return response.data
    }
)
export const getRubblData = createAsyncThunk(
    GET_RUBBL_DATA,
    async (data) => {
        const response = await API.getRubblData(data)
        return response.data
    }
)
export const getProductsList = createAsyncThunk(
    PRODUCTS_LIST,
    async (data) => {
        const response = await API.productsLists(data)
        return response.data
    }
)
export const searchProducts = createAsyncThunk(
    SEARCH_PRODUCTS, async (filterField = null) => filterField
)
export const createProduct = createAsyncThunk(
    CREATE_PRODUCTS,
    async (data) => {
        const response = await API.createProduct(data)
        return response.data
    }
)
export const deleteProduct = createAsyncThunk(
    DELETE_PRODUCTS,
    async (id) => {
        const response = await API.deleteProduct(id)
        return response.data
    }
)
export const updateProduct = createAsyncThunk(
    UPDATE_PRODUCTS,
    async (data) => {
        const response = await API.updateProduct(data)
        return response.data
    }
)
export const toggleAddEditProducts = createAsyncThunk(
    TOGGLE_ADD_EDIT_PRODUCTS, async (item = null) => item
)

