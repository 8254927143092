// import axios from 'axios';

// export const getAccessToken = (token) => {
//   console.log("token?.accessToken",token?.accessToken);
//     axios.defaults.headers.common = {
//       'Authorization': `Bearer ${token?.accessToken}`,
//       'Accept': 'application/json'
//     }
//     return axios.defaults.headers.common
// }

export const get = (key) =>{
    return localStorage.getItem(key)
}

export const save = (key,value) =>{
  return localStorage.setItem(key,value)
}

export const remove = async(key) =>{
  return  localStorage.removeItem(key)
}