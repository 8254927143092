const SERVER_HOST = 'https://api.rubbl.com.au/api/';
const USER_LOGIN = `${SERVER_HOST}admin/login`;
const ACCOUNT_LIST = `${SERVER_HOST}account/list`;
const SUPPLIER_LIST = `${SERVER_HOST}account/list`;
const TRANSPORTER_LIST = `${SERVER_HOST}account/list`;
const ORDERS_LIST = `${SERVER_HOST}orders/contractor`;
const EMAIL_TEMPLATE = `${SERVER_HOST}email-template/list`;
const MATERIAL_LIST = `${SERVER_HOST}all-materials`;
const NOTIFICATION_TEMPLATE = `${SERVER_HOST}notification-template/list`;
const INVOICE_LIST = `${SERVER_HOST}invoice/list`;
const PRODUCTS_LIST = `${SERVER_HOST}rubbish/product`;
const CREATE_PRODUCTS = `${SERVER_HOST}rubbish/product`;
const ADD_CONTRACTOR = `${SERVER_HOST}account/create`;
const ADD_SUPPLIER = `${SERVER_HOST}account/create`;
const ADD_TRANSPORTER = `${SERVER_HOST}account/create`;
const UPLOAD_IMAGE = `${SERVER_HOST}files/image`;
const UPDATE_CONTRACTOR = `${SERVER_HOST}account/update`;
const UPDATE_SUPPLIER = `${SERVER_HOST}account/update`;
const UPDATE_TRANSPORTER =  `${SERVER_HOST}account/update`;
const ACTIVE_SITES = `${SERVER_HOST}sites`;
const CONTRACTOR_ORDER_LIST = `${SERVER_HOST}orders/contractor`;
const CONTRACTOR_INVOICE_LIST = `${SERVER_HOST}invoice/list`;
const SUPPLIER_ORDER_LIST = `${SERVER_HOST}orders/supplier/loadings`;
const SUPPLIER_ORDER_DETAIL = `${SERVER_HOST}order/details`;
const SUPPLIER_MATERIAL_LIST = `${SERVER_HOST}all-materials`;
const TRANSPORTER_ORDER_LIST = `${SERVER_HOST}orders/transporter/loadings`;
const DRIVERS_LIST = `${SERVER_HOST}drivers/list`;
const VEHICLE_LIST = `${SERVER_HOST}vehicles/list`;
const HELP_CENTER = `${SERVER_HOST}log/list`;
const DELETE_HELP_CENTER = `${SERVER_HOST}log/delete`;
const MATERIAL_CATEGORY = `${SERVER_HOST}categories/list`;
const ADD_MATERIAL = `${SERVER_HOST}material/create`;
const UPDATE_MATERIAL = `${SERVER_HOST}material/update`
const VEHICLE_TYPE = `${SERVER_HOST}vehicletypes`
const TIERED_PRICING = `${SERVER_HOST}tiered-price/add`;
const DASHBOARD_COUNT = `${SERVER_HOST}dashboard/count`;
const CHART_DATA = `${SERVER_HOST}dashboard/chart`;
const ADD_EMAIL_TEMPLATE = `${SERVER_HOST}email-template/create`;
const UPDATE_EMAIL_TEMPLATE = `${SERVER_HOST}email-template/update`;
const EDIT_NOTIFICATION_TEMPLATE = `${SERVER_HOST}notification-template/update`;
const UPDATE_INVOICE = `${SERVER_HOST}invoice/update`;
const SEND_RECEIPT = `${SERVER_HOST}send_receipt`;
const EDIT_SUPPLIER_MATERIAL = `${SERVER_HOST}suppliers/material-create`;
const DELETE_MATERIAL = `${SERVER_HOST}material/delete`;
// const ORDER_CANCEL = `${SERVER_HOST}order/cancel`;
const FORGOT_PASSWORD = `${SERVER_HOST}users/forgot-password`;
const USER_LOGOUT = `${SERVER_HOST}users/logout`;
const GET_TERMS_CONDITIONS = `${SERVER_HOST}pages/detail`;
const UPDATE_TERMS_CONDITIONS = `${SERVER_HOST}pages/update`;
const GET_PRIVACY_POLICY = `${SERVER_HOST}pages/detail`
const UPDATE_PRIVACY_POLICY = `${SERVER_HOST}pages/update`
const GET_ABOUT = `${SERVER_HOST}pages/detail`;
const UPDATE_ABOUT = `${SERVER_HOST}pages/update`;
const INVOICE_DOWNLOAD = `${SERVER_HOST}download_invoice`;
const GET_TIERED_PRICING = `${SERVER_HOST}tiered-price`;
const SUPPLIER_PAYMENTS = `${SERVER_HOST}payment/list`;
const UPDATE_SUPPLIER_PAYMENTS = `${SERVER_HOST}payment/update`;
const TRANSPORTER_PAYMENTS = `${SERVER_HOST}payment/list`;
const UPDATE_TRANSPORTER_PAYMENTS = `${SERVER_HOST}payment/update`;
const GET_LOAD_ASSUMPTIONS = `${SERVER_HOST}load-assumption`;
const CHANGE_PASSWORD = `${SERVER_HOST}users/change-password`;
const CANCEL_ORDER = `${SERVER_HOST}order-cancel`;
const GET_DELIVERY_PRICE = `${SERVER_HOST}delivery-price`;
const FREE_DELIVERY_PRICE = `${SERVER_HOST}contractor/free-delivery`;
const DELETE_DELIVERY_ITEM = `${SERVER_HOST}delivery-price/`;
const GET_SUBURB_DATA = `${SERVER_HOST}suburb`;
const GET_RANGES_DATA = `${SERVER_HOST}supplier-default-range`;
const GET_RANGES_AREA = `${SERVER_HOST}service-area`;
const SET_PROMOTIONS = `${SERVER_HOST}promotion`;
const POST_PAGES_CREATE = `${SERVER_HOST}pages/create`;
const UPDATE_RUBBISH_ABOUT = `${SERVER_HOST}pages/update`;
const GET_RUBBL_DATA = `${SERVER_HOST}pages/detail`;
const GET_CATEGORY = `${SERVER_HOST}rubbish/category`;
const GET_ORDERS = `${SERVER_HOST}rubbish/order`;
const UPDATE_ORDERS = `${SERVER_HOST}rubbish/order/update`;
const GET_RUBBISH_INVOICE = `${SERVER_HOST}rubbish/invoice`;
const UPDATE_RUBBISH_INVOICE = `${SERVER_HOST}rubbish/invoice`;
const CHANGE_RUBBISH_ORDER_INVOICE = `${SERVER_HOST}rubbish/order/invoice`;

const Config = {
    SERVER_HOST,
    USER_LOGIN,
    ACCOUNT_LIST,
    SUPPLIER_LIST,
    TRANSPORTER_LIST,
    ORDERS_LIST,
    EMAIL_TEMPLATE,
    MATERIAL_LIST,
    NOTIFICATION_TEMPLATE,
    INVOICE_LIST,
    ADD_CONTRACTOR,
    ADD_SUPPLIER,
    UPLOAD_IMAGE,
    ADD_TRANSPORTER,
    UPDATE_CONTRACTOR,
    UPDATE_SUPPLIER,
    UPDATE_TRANSPORTER,
    ACTIVE_SITES,
    CONTRACTOR_ORDER_LIST,
    CONTRACTOR_INVOICE_LIST,
    SUPPLIER_ORDER_LIST,
    SUPPLIER_ORDER_DETAIL,
    SUPPLIER_MATERIAL_LIST,
    TRANSPORTER_ORDER_LIST,
    DRIVERS_LIST,
    VEHICLE_LIST,
    HELP_CENTER,
    DELETE_HELP_CENTER,
    MATERIAL_CATEGORY,
    ADD_MATERIAL,
    UPDATE_MATERIAL,
    VEHICLE_TYPE,
    TIERED_PRICING,
    DASHBOARD_COUNT,
    CHART_DATA,
    ADD_EMAIL_TEMPLATE,
    UPDATE_EMAIL_TEMPLATE,
    EDIT_NOTIFICATION_TEMPLATE,
    UPDATE_INVOICE,
    SEND_RECEIPT,
    EDIT_SUPPLIER_MATERIAL,
    DELETE_MATERIAL,
    // ORDER_CANCEL,
    FORGOT_PASSWORD,
    USER_LOGOUT,
    GET_TERMS_CONDITIONS,
    UPDATE_TERMS_CONDITIONS,
    GET_PRIVACY_POLICY,
    UPDATE_PRIVACY_POLICY,
    GET_ABOUT,
    UPDATE_ABOUT,
    INVOICE_DOWNLOAD,
    GET_TIERED_PRICING,
    SUPPLIER_PAYMENTS,
    UPDATE_SUPPLIER_PAYMENTS,
    TRANSPORTER_PAYMENTS,
    UPDATE_TRANSPORTER_PAYMENTS,
    GET_LOAD_ASSUMPTIONS,
    CHANGE_PASSWORD,
    CANCEL_ORDER,
    GET_DELIVERY_PRICE,
    GET_SUBURB_DATA,
    DELETE_DELIVERY_ITEM,
    GET_RANGES_DATA,
    GET_RANGES_AREA,
    SET_PROMOTIONS,
    FREE_DELIVERY_PRICE,
    POST_PAGES_CREATE,
    UPDATE_RUBBISH_ABOUT,
    GET_RUBBL_DATA,
    GET_CATEGORY,
    PRODUCTS_LIST,
    CREATE_PRODUCTS,
    GET_ORDERS,
    UPDATE_ORDERS,
    GET_RUBBISH_INVOICE,
    UPDATE_RUBBISH_INVOICE,
    CHANGE_RUBBISH_ORDER_INVOICE
}

export default Config;

