import {createAsyncThunk} from "@reduxjs/toolkit";
import API from "../services/supplier";
import {
    ADD_DELIVERY_PRICE, ADD_PROMOTIONS,
    ADD_RANGES_LISTS,
    ADD_SUBURB_LISTS,
    DELETE_DELIVERY_ITEM_WITH_ID,
    DELETE_SUBURB_LISTS, GET_PROMOTIONS,
    GET_RANGES_LISTS,
    GET_SUBURB_LISTS,
    GET_SUPPLIER_CONSTANT, SET_RANGES_AREA,
    UPDATE_DELIVERY_PRICE,
    UPDATE_SUBURB_LISTS,
} from "../types/supplierContstant";

export const getSuppliers = createAsyncThunk(
    GET_SUPPLIER_CONSTANT,
    async (data) => {
        const response = await API.getSupplierLists(data)
        return response.data;
    }
)


export const getSuburbLists = createAsyncThunk(
    GET_SUBURB_LISTS,
    async () => {
        const response = await API.getSuburbData()
        return response.data;
    }
)

export const setServiceArea = createAsyncThunk(
    SET_RANGES_AREA,
    async (data) => {
        const response = await API.setServiceArea(data)
        return response.data;
    }
)

export const deleteSuburbLists = createAsyncThunk(
    DELETE_SUBURB_LISTS,
    async (id) => {
        const response = await API.deleteSuburbData(id)
        return response.data;
    }
)
export const addSuburbLists = createAsyncThunk(
    ADD_SUBURB_LISTS,
    async (data) => {
        const response = await API.addSuburbData(data)
        return response.data;
    }
)
export const updateSuburbLists = createAsyncThunk(
    UPDATE_SUBURB_LISTS,
    async (data) => {
        const response = await API.updateSuburbLists(data)
        return response.data;
    }
)

export const getRangesLists = createAsyncThunk(
    GET_RANGES_LISTS,
    async (id) => {
        const response = await API.getRangesLists(id)
        return response.data;
    }
)

export const addRangesLists = createAsyncThunk(
    ADD_RANGES_LISTS,
    async (data) => {
        const response = await API.addRangesLists(data)
        return response.data;
    }
)

export const addDeliveryPrice = createAsyncThunk(
    ADD_DELIVERY_PRICE,
    async (data) => {
        const response = await API.addDelivery(data)
        return response.data;
    }
)

export const updateDeliveryPrice = createAsyncThunk(
    UPDATE_DELIVERY_PRICE,
    async (data) => {
        const response = await API.updateDelivery(data)
        return response.data;
    }
)

export const deleteDeliveryItem = createAsyncThunk(
    DELETE_DELIVERY_ITEM_WITH_ID,
    async (data) => {
        const response = await API.deleteSuburb(data)
        return response.data;
    }
)

export const addPromotions = createAsyncThunk(
    ADD_PROMOTIONS,
    async (data) => {
        const response = await API.addPromotions(data)
        return response.data;
    }
)

export const getPromotions = createAsyncThunk(
    GET_PROMOTIONS,
    async (id) => {
        const response = await API.getPromotions(id)
        console.log("response1",response)
        return response.data;
    }
)

export const setFreeDeliveryStatus = createAsyncThunk(
    DELETE_DELIVERY_ITEM_WITH_ID,
    async (data) => {
        const response = await API.setFreeDelivery(data)
        return response.data;
    }
)