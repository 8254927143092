import {createAsyncThunk} from "@reduxjs/toolkit";
import API from "../services/Invoices";
import {CHANGE_RUBBISH_ORDER_INVOICE, GET_RUBBISH_INVOICE, SEARCH_INVOICE} from "../types";

export const getInvoicesLists = createAsyncThunk(
    GET_RUBBISH_INVOICE, async (data) => {
        return await API.getInvoices(data)
    },
)

export const updateInvoices = createAsyncThunk(
    GET_RUBBISH_INVOICE,
    async (data) => {
        const response = await API.updateInvoices(data)
        return response.data
    }
)
export const changePaymentMethod = createAsyncThunk(
    CHANGE_RUBBISH_ORDER_INVOICE,
    async (data) => {
        const response = await API.changePaymentMethod(data)
        return response.data
    }
)

export const searchInvoice = createAsyncThunk(
    SEARCH_INVOICE, async (filterField = null) => filterField
)