import { reducer as formReducer } from 'redux-form';
import authReducer from "./authReducer";
import supplierSlice from "./supplier";
import categorySlice from "./category";
import orderSlice from "./orders";
import invoiceSlice from "./invoice";

const rootReducer = {
    auth: authReducer,
    supplier: supplierSlice,
    category: categorySlice,
    order: orderSlice,
    form: formReducer,
    invoice: invoiceSlice
}
export default rootReducer;