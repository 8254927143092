import {createAsyncThunk} from "@reduxjs/toolkit";
import API from "../services/orders";
import {GET_ORDERS, ORDER_DETAILS, SEARCH_ORDERS, UPDATE_ORDERS} from "../types/OrdersConstant";

export const getOrdersLists = createAsyncThunk(
    GET_ORDERS, async (data) => {
        return await API.getOrders(data)
    },
)
export const cancelOrders = createAsyncThunk(
    GET_ORDERS, async (id) => {
        return await API.cancelOrders(id)
    },
)
export const orderDetails = createAsyncThunk(
    ORDER_DETAILS, async (data) => {
        return data
    },
)
export const updateOrders = createAsyncThunk(
    UPDATE_ORDERS, async (data) => {
        return await API.updateOrders(data)
    },
)

export const searchOrders = createAsyncThunk(
    SEARCH_ORDERS, async (filterField = null) => filterField
)