import axios from "axios";
import {createUrlParams, get} from "../utils";
import Config from "../config";
import {toast} from "react-toastify";

let instance = axios.create({
    baseURL: 'http://44.226.238.61/',
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

instance.interceptors.request.use(function (config) {
    const token = get("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

const API = {
    getSupplierLists:(data)=>{
        return instance
            .get(`${Config.GET_DELIVERY_PRICE}?${createUrlParams(data)}`)
            .then(res => {
                return res
            })
            .catch(e => { return e?.response });
    },
    getSuburbData:()=>{
        return instance
            .get(`${Config.GET_SUBURB_DATA}`)
            .then(res => {
                return res
            })
            .catch(e => { return e?.response });
    },
    deleteSuburbData:(id)=>{
        return instance
            .delete(`${Config.GET_SUBURB_DATA}/${id}`)
            .then(res => {
                toast.success("Data has been Deleted.");
                return res
            })
            .catch(e => {
                toast.error("Error while Deleting data.");
                return e?.response
            });
    },
    addSuburbData:(data)=>{
        return instance
            .post(`${Config.GET_SUBURB_DATA}`,data)
            .then(res => {
                toast.success("Data has been saved successfully.");
                return res
            })
            .catch(e => {
                toast.error("Error while Saving data.");
                return e?.response
            });
    },
    updateSuburbLists:(data)=>{
        console.log(data)
        return instance
            .put(`${Config.GET_SUBURB_DATA}/${data.id}`,data.list)
            .then(res => {
                toast.success("Data has been updated successfully.");
                return res
            })
            .catch(e => {
                toast.error("Error while updating data.");
                return e?.response
            });
    },
    deleteSuburb:(id = "")=>{
        return instance
            .delete(`${Config.DELETE_DELIVERY_ITEM}${id}`)
            .then(res => {
                toast.success("Data has been Deleted.");
                return res
            })
            .catch(e => {
                toast.error("Error while Deleting data.");
                return e?.response
            });
    },
    addDelivery:(data = {})=>{
        return instance
            .post(`${Config.GET_DELIVERY_PRICE}`,data)
            .then(res => {
                toast.success("Data has been saved successfully.");
                return res
            })
            .catch(e => {
                toast.error("Error while Saving data.");
                return e?.response
            });
    },
    updateDelivery:(data = {})=>{
        const {id,...rest} = data;
        return instance
            .put(`${Config.GET_DELIVERY_PRICE}/${id}`,rest)
            .then(res => {
                toast.success("Data has been updated successfully.");
                return res
            })
            .catch(e => {
                toast.error("Error while Updating data.");
                return e?.response
            });
    },
    getRangesLists:(id)=>{
        return instance
            .get(`${Config.GET_RANGES_DATA}?supplier_id=${id}`)
            .then(res => {
                return res
            })
            .catch(e => { return e?.response });
    },
    addRangesLists:(data = {})=>{
        return instance
            .post(`${Config.GET_RANGES_DATA}`,data)
            .then(res => {
                toast.success("Data has been saved successfully.");
                return res
            })
            .catch(e => {
                toast.error("Error while Saving data.");
                return e?.response
            });
    },
    setServiceArea:(data = {})=>{
        return instance
            .post(`${Config.GET_RANGES_AREA}`,data)
            .then(res => {
                toast.success("Data has been saved successfully.");
                return res
            })
            .catch(e => {
                toast.error("Error while Saving data.");
                return e?.response
            });
    },
    addPromotions:(data = {})=>{
        return instance
            .post(`${Config.SET_PROMOTIONS}`, {promotions:data})
            .then(res => {
                toast.success("Data has been saved successfully.");
                return res
            })
            .catch(e => {
                toast.error("Error while Saving data.");
                return e?.response
            });
    },
    getPromotions:()=>{
        return instance
            .get(`${Config.SET_PROMOTIONS}`)
            .then(res => {
                return res
            })
            .catch(e => { return e?.response });
    },
        setFreeDelivery:(data = {})=>{
            return instance
                .post(`${Config.FREE_DELIVERY_PRICE}`,data)
                .then(res => {
                })
                .catch(e => {
                    return e?.response
                });
        }
}

export default API;