import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    GET_CATEGORY,
    SEARCH_CATEGORY,
    TOGGLE_ADD_EDIT_CATEGORY,
    UPDATE_CATEGORY, UPDATE_LABOR_CHARGE
} from "../types/CategoryConstant";
import API from "../services/category";

export const getCategoryLists = createAsyncThunk(
    GET_CATEGORY, async (data) => {
        return await API.getCategory(data)
    },
)

export const deleteCategory = createAsyncThunk(
    DELETE_CATEGORY, async (data= null) => {
        return await API.deleteCategoryItem(data)
    }
)

export const createNewCategory = createAsyncThunk(
    CREATE_CATEGORY, async (data) => {
        return await API.createCategory(data)
    }
)

export const updateCategory = createAsyncThunk(
    UPDATE_CATEGORY, async (data= null) => {
        return await API.createCategory(data)
    }
)

export const updateLabourCharges = createAsyncThunk(
    UPDATE_LABOR_CHARGE, async (data= null) => {
        return await API.updateLabourCharge(data)
    }
)

export const getLaborChargeData = createAsyncThunk(
    UPDATE_LABOR_CHARGE, async () => {
        return await API.getLaborCharge()
    }
)

export const searchCategory = createAsyncThunk(
    SEARCH_CATEGORY, async (data= null) => data
)


export const toggleAddEditCategory = createAsyncThunk(
    TOGGLE_ADD_EDIT_CATEGORY, async (item = null) => item
)
