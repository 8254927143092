import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import loginImg from '../../../assets/images/rubbl.png'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch  } from 'react-redux';
import { forgotPassword } from 'src/actions/AuthActions'

const ForgotPassword = () => {

    const [input, setInput] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const handelChange = (event) => {
       setInput({ ...input, [event.target.name]: event.target.value })
    }

    const handelSubmit = async (e) => {
        e.preventDefault()
        if (input) {
          if (!input.email) {
           toast.error('Please Enter Email')
          }
          else {
            let paylaod = {
              email: input.email,
              role: 'admin'
            }
            dispatch(forgotPassword(paylaod)).unwrap()
          .then(res => {
            if (res?.success) {
              toast.success("Temporary password sent.")
              navigate('/')
            }
            else{
              alert("Invalid Email")
            }
          })
          }
        }
      }

    return(
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Forgot Password</h1>
                      <p className="text-medium-emphasis">Enter Email to send password</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          name="email"
                          placeholder="Email"
                          autoComplete="email"
                          onChange={handelChange}
                        />
                      </CInputGroup>
                      <CRow>
                      </CRow>
                      <CRow>
                        <CCol xs={6}>
                          <CButton
                            color="primary"
                            className="px-4"
                            style={{ backgroundColor: 'rgb(0,126,229)', border: 'none' }}
                            onClick={handelSubmit}
                          >
                            Send
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard
                  className="text-white py-5"
                  style={{ width: '44%', backgroundColor: 'rgb(0,126,229)' }}
                >
                  <CCardBody className="text-center d-flex justify-content-center align-items-center">
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <img
                        style={{ width: '250px', height: '70px' }}
                        src={loginImg}
                        alt={'login Image'}
                      />
                      <span
                        style={{
                          fontSize: '17px',
                          fontFamily: 'Montserrat',
                          marginTop: '20px',
                        }}
                      >
                        Admin panel
                      </span>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    )
}

export default ForgotPassword