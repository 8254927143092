import React, { Component, Suspense } from 'react'
// import { HashRouter, Route, Routes } from 'react-router-dom'
// import { isUserAuthenticated } from './helpers/auth'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
// import { isUserAuthenticated } from 'src/helpers/auth'
import { getAccessToken } from './utils/localStorage';
import Login from './views/pages/login/Login'
import ForgotPassword from './views/pages/forgotPassword/forgotPassword';
import { get } from './utils/localStorage';
import './scss/style.scss'
import "react-toastify/dist/ReactToastify.css";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        secondary: {
            main: '#007EE5'
        }
    }
});

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const API_URL = process.env.REACT_APP_API_URL
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
// const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/forgotPassword'))


const isUserAuthenticated = () => {
  let token = get("token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

class App extends Component {
  render() {
    return (
      <React.Fragment>
                {/* <ToastContainer position="top-left"/> */}
          <MuiThemeProvider theme={theme}>
        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME ?? ''}>
          <Suspense fallback={loading} className="loaderStyle">
            <Routes>
              <Route  path="/" name="Login Page" element={<Login />} /> 
              <Route  path="/forgotPassword" name="Forgot Password Page" element={<ForgotPassword />} /> 
              <Route path="*" name="Home" element={<DefaultLayout/>}/> 
              {/* <Route exact path="/privacy-policy" name="Register Page" element={<PrivacyPolicy />} /> */}
              {/* <Route  path={ isUserAuthenticated() ? "*" : "/login"} name={ isUserAuthenticated() ? "Home" : "Login Page"} element={isUserAuthenticated()  ?    <DefaultLayout/> : <Login/> } /> */}
              {/* <Route path="/login" name="Home" element={<Login /> } /> */}
            </Routes>
            
          </Suspense>
          
        </BrowserRouter>
          </MuiThemeProvider>

        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnHover={false}
        />
      </React.Fragment>
    )
  }
}

export default App
