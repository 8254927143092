import {createSlice} from "@reduxjs/toolkit";
import {
    createNewCategory,
    deleteCategory,
    getCategoryLists,
    searchCategory,
    toggleAddEditCategory,
    updateCategory
} from "../actions/category";
import _ from "lodash";


const initialState = {
    loading: false,
    isOpenAddNewProductModal: false,
    allCategory: [],
    categoryLists: [],
    currentElement:null
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: {
        [getCategoryLists.pending]: (state) => {
            state.loading = true;
        },
        [getCategoryLists.fulfilled]: (state, action) => {
            state.loading = false;
            state.allCategory = action.payload;
            state.categoryLists = action.payload;
        },
        [deleteCategory.fulfilled]: (state, action) => {
            state.categoryLists = _.filter(state.categoryLists, (category) => category.id !== action.payload);
            state.allCategory = _.filter(state.allCategory, (category) => category.id !== action.payload);
        },
        [searchCategory.fulfilled]: (state, action) => {
            state.categoryLists = _.filter(state.allCategory, (category) => category.name.includes(action.payload?.trim()));
        },
        [updateCategory.fulfilled]: (state, action) => {
            const categoryLists = state.categoryLists
            const index = categoryLists.findIndex(ele=> ele.id === action.payload.id);
            categoryLists[index] = action.payload;
            state.categoryLists = [...categoryLists];
            state.allCategory = [...categoryLists];
            state.isOpenAddNewProductModal = false;
        },
        [createNewCategory.fulfilled]: (state, action) => {
            state.categoryLists = _.concat(state.categoryLists, action.payload);
            state.allCategory = _.concat(state.categoryLists, action.payload);
            state.isOpenAddNewProductModal = false;
        },
        [toggleAddEditCategory.fulfilled]: (state,action) => {
            state.currentElement = action.payload;
            state.isOpenAddNewProductModal = !state.isOpenAddNewProductModal;
        }
    }
})

export default categorySlice.reducer;