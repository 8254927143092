import {createSlice} from "@reduxjs/toolkit";
import {getInvoicesLists, searchInvoice} from "../actions/Invoices";

const initialState = {
    loading: false,
    invoiceList: [],
    invoice: {}
}

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {},
    extraReducers: {
        [getInvoicesLists.pending]: (state) => {
            state.loading = !state.loading;
        },
        [getInvoicesLists.fulfilled]: (state, action) => {
            if (action?.payload?.success && action.payload) {
                state.loading = false;
                state.invoiceList = action?.payload?.data
            }
        },
        [searchInvoice.fulfilled]: (state, action) => {
            let filteredList = [...state.invoiceList];

            if (action.payload.status) {
                filteredList = filteredList.filter(invoice => invoice.status === action.payload.status);
            }
            if (action.payload.shipment_no) {
                filteredList = filteredList.filter(invoice => invoice.order && invoice.order.order_id.includes(action.payload.shipment_no));
            }
            state.invoiceList = [...filteredList];
        },
    }
})

export default invoiceSlice.reducer;