import {createSlice} from "@reduxjs/toolkit";
import {
    addDeliveryPrice,
    addSuburbLists,
    addRangesLists,
    getRangesLists,
    getSuburbLists,
    getSuppliers,
    updateSuburbLists,
    getPromotions,
    toggleDeliveryPricingModal, updateDeliveryPrice, addPromotions
} from "../actions/supplier";

const initialState = {
    loading:false,
    suburbLoading:false,
    rangesListsLoading:false,
    addDeliveryLoading:false,
    addRangesLoading:false,
    addSuburbLoading:false,
    deliveryDriverData:[],
    suburbListsData:[],
    rangesListsData:[],
    promotions:[],
    addPromotions:false,
}

const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {},
    extraReducers: {
        [getSuppliers.pending]: (state) => {
            state.loading = true;
        },
        [getSuburbLists.pending]: (state) => {
            state.suburbLoading = true;
        },
        [addPromotions.pending]: (state) => {
            state.addPromotions = true;
        },
        [addPromotions.fulfilled]: (state) => {
            state.addPromotions = false;
        },
        [addDeliveryPrice.pending ]: (state) => {
            state.addDeliveryLoading = true;
        },
        [updateDeliveryPrice.pending ]: (state) => {
            state.addDeliveryLoading = true;
        },
        [addDeliveryPrice.fulfilled]: (state) => {
            state.addDeliveryLoading = false;
        },
        [updateDeliveryPrice.fulfilled]: (state) => {
            state.addDeliveryLoading = false;
        },
        [getRangesLists.pending]: (state) => {
            state.rangesListsLoading = true;
        },
        [addRangesLists.pending ]: (state) => {
            state.addRangesLoading = true;
        },
        [addRangesLists.fulfilled]: (state) => {
            state.addRangesLoading = false;
        },
        [updateSuburbLists.pending ]: (state) => {
            state.addSuburbLoading = true;
        },
        [updateSuburbLists.fulfilled]: (state) => {
            state.addSuburbLoading = false;
        },
        [addSuburbLists.pending ]: (state) => {
            state.addSuburbLoading = true;
        },
        [addSuburbLists.fulfilled]: (state) => {
            state.addSuburbLoading = false;
        },
        [getSuppliers.fulfilled]: (state, action) => {
            if (action?.payload?.success && action.payload?.data) {
                state.loading = false;
                state.deliveryDriverData = action?.payload?.data
            }
        },
        [getSuburbLists.fulfilled]: (state, action) => {
            if (action?.payload?.success && action.payload?.data) {
                state.suburbListsData = action?.payload?.data
                state.suburbLoading = false
            }
        },
        [getRangesLists.fulfilled]: (state, action) => {
            if (action?.payload?.success && action.payload?.data) {
                state.rangesListsData = action?.payload?.data
                state.rangesListsLoading = false;
            }
        },
        [getPromotions.fulfilled]: (state,action) => {
            if (action?.payload?.success && action.payload?.data) {
                state.promotions = action?.payload?.data
            }
        },
    }
})

export default supplierSlice.reducer;