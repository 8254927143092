import axios from "axios";
import {get} from "../utils";
import Config from "../config";
import {toast} from "react-toastify";

let instance = axios.create({
    baseURL: 'http://44.226.238.61/',
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});
instance.interceptors.request.use(function (config) {
    const token = get("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

const API = {
    getInvoices: async () => {
        return instance
            .get(Config.GET_RUBBISH_INVOICE)
            .then((res) => {
                if (res && res.data) {
                    return res.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    updateInvoices: async (data) => {
        return instance
            .post(Config.UPDATE_RUBBISH_INVOICE, data)
            .then((res) => {
                toast.success("Invoice has been updated")
                return res;
            })
            .catch((err) => {
                toast.error("Something went wrong.")
                return err;
            });
    },
    changePaymentMethod: async (data) => {
        return instance
            .post(Config.CHANGE_RUBBISH_ORDER_INVOICE, data)
            .then((res) => {
                toast.success("Invoice has been updated")
                return res;
            })
            .catch((err) => {
                toast.error("Something went wrong.")
                return err;
            });
    },
}

export default API