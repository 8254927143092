import { createSlice } from '@reduxjs/toolkit'
import {
  supplierList,
  userLogin,
  transporterList,
  ordersList,
  emailTemplate,
  materialList,
  notificationTemplate,
  invoiceList,
  addContractor,
  uploadImage,
  addSupplier,
  addTransporter,
  updateContractor,
  updateSupplier,
  updateTransporter,
  activeSites,
  contractorOrderList,
  contractorInvoiceList,
  supplierOrderList,
  supplierOrderDetail,
  supplierMaterialList,
  transporterOrderList,
  driversList,
  vehicleList,
  helpCenter,
  deleteHelpCenter,
  materialCategory,
  addMaterial,
  updateMaterial,
  vehicleType,
  tieredPricing,
  dashboardCount,
  chartData,
  addEmailTemplate,
  updateEmailTemplate,
  updateNotificationTemplate,
  updateInvoice,
  sendReceipt,
  editSupplierMaterial,
  deleteMaterial,
  orderCancel,
  forgotPassword,
  addTermsConditions,
  getTermsConditions,
  updateTermsConditions,
  getPrivacyPolicy,
  addPrivacyPolicy,
  updatePrivacyPolicy,
  addAbout,
  updateAbout,
  getAbout,
  invoiceDownload,
  getTieredPricing,
  supplierPayments,
  updateSupplierPayments,
  transporterPayments,
  updateTransporterPayments,
  getLoadAssumptions,
  changePassword,
  cancelOrder,
  ToggleSidebar,
  getRubblData,
  getProductsList,
  toggleAddEditProducts,
  createProduct,
  updateProduct,
  searchProducts
} from 'src/actions/AuthActions'
import { accountList } from 'src/actions/AuthActions'


const initialState = {
  isLoggedIn: false,
  user: {},
  token: '',
  loading: false,
  assignRole: '',
  image: '',
  imageLoading: false,
  isConnected: false,
  isOpenAddProductModal: false,
  currentProduct: null,
  contracterList: [],
  supplierList: [],
  transporterList: [],
  orderList: [],
  emailTemplateList: [],
  materialList: [],
  notificationTemplateList: [],
  invoiList: [],
  update: '',
  updatedTransporter: {},
  activeSitesList: [],
  contractorOrdersList: [],
  contractorInvoicesList: [],
  supplierOrdersList: [],
  supplierOrderDetailList: [],
  supplierMaterialsList: [],
  transporterOrdersList: [],
  driverList: [],
  vehiclesList: [],
  helpCenterList: [],
  materialCategoryList: [],
  updateMaterialList: '',
  vehicleTypeList: [],
  dashboardCounts: {},
  chartDataList: {},
  termsAndConditions: '',
  privacyPolicy: '',
  about: '',
  rubblAbout: {},
  tieredPricingList: [],
  supplierPaymentsList: [],
  transporterPaymentsList: [],
  loadAssumptions: [],
  productsList: [],
  searchProductsList: [],
  sidebarShow:true
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {

    // [registerUser.fulfilled]: (state, action) => {
    //   if (action?.payload?.success === false) {
    //     handleError(action.payload)
    //   }
    // },

    [userLogin.pending]: (state) => {
      state.loading = true;
    },
    [ToggleSidebar.pending]: (state) => {
      state.sidebarShow = !state.sidebarShow;
    },

    [userLogin.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data?.accessToken) {
        state.token = action.payload?.data?.accessToken;
        state.user = action.payload.data.user;
        // state.name = action.payload.data.user.name;
        state.isLoggedIn = true;
        state.loading = false;
        // isUserAuthenticated(action.payload.data.accessToken)
        console.log("action.payload?.data", action.payload.data.accessToken);
        console.log("action.payload.data.user.name",state.name);
      }
      else {
        state.loading = false;
        state.token = ""
        state.user = {}
        // handleError(action.payload)
      }
    },

    [accountList.pending]: (state) => {
      state.loading = true;
    },

    [accountList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.contracterList = action?.payload?.data
      }
    },
    [supplierList.pending]: (state) => {
      state.loading = true;
    },
    [supplierList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.supplierList = action?.payload?.data
      }
    },
    [transporterList.pending]: (state) => {
      state.loading = true;
    },
    [transporterList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.transporterList = action?.payload?.data
      }
    },
    [ordersList.pending]: (state) => {
      state.loading = true;
    },
    [ordersList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.orderList = action?.payload?.data
      }
    },
    [emailTemplate.pending]: (state) => {
      state.loading = true;
    },
    [emailTemplate.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.emailTemplateList = action?.payload?.data
      }
    },
    [materialList.pending]: (state) => {
      state.loading = true;
    },
    [materialList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.materialList = action?.payload?.data
      }
    },
    [notificationTemplate.pending]: (state) => {
      state.loading = true;
    },
    [notificationTemplate.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.notificationTemplateList = action?.payload?.data
      }
    },
    [invoiceList.pending]: (state) => {
      state.loading = true;
    },
    [invoiceList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.invoiList = action?.payload?.data
      }
    },
    [addContractor.pending]: (state) => {
      // state.loading = true;
    },
    [addContractor.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.contracterList))
        // console.log("action.payload?.data",List);
        state.update = new Date()
      }
    },
    [addSupplier.pending]: (state) => {
      // state.loading = true;
    },
    [addSupplier.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.supplierList))
        // console.log("action.payload?.data",List);
        state.update = new Date()
      }
    },
    [addTransporter.pending]: (state) => {
      // state.loading = true;
    },
    [addTransporter.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.transporterList))
        // console.log("action.payload?.data",List);
        state.update = new Date()
      }
    },
    [uploadImage.pending]: (state, action) => {
      // state.imageLoading = true;
    },
    [uploadImage.fulfilled]: (state, action) => {
      // state.imageLoading = false;
      if (action?.payload?.success)
        console.log("action?.payload?.data", action?.payload?.data);
      state.image = action?.payload?.data?.url
    },
    [updateContractor.pending]: (state) => {
      // state.loading = true;
    },
    [updateContractor.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.contracterList))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [updateSupplier.pending]: (state) => {
      // state.loading = true;
    },
    [updateSupplier.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.supplierList))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [updateTransporter.pending]: (state) => {
      // state.loading = true;
    },
    [updateTransporter.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.transporterList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        state.update = new Date()
        console.log("List", List);
      }
    },
    [activeSites.pending]: (state) => {
      state.loading = true;
    },
    [activeSites.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.activeSitesList = action?.payload?.data
      }
    },
    [contractorOrderList.pending]: (state) => {
      state.loading = true;
    },
    [contractorOrderList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.contractorOrdersList = action?.payload?.data
      }
    },
    [contractorInvoiceList.pending]: (state) => {
      state.loading = true;
    },
    [contractorInvoiceList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.contractorInvoicesList = action?.payload?.data
      }
    },
    [supplierOrderList.pending]: (state) => {
      state.loading = true;
    },
    [supplierOrderList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.supplierOrdersList = action?.payload?.data
      }
    },
    [supplierOrderDetail.pending]: (state) => {
      state.loading = true;
    },
    [supplierOrderDetail.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.supplierOrdersDetailList = action?.payload?.data
        // console.log("state.supplierOrdersDetailList",state.supplierOrdersDetailList?.shipments);
      }
    },
    [supplierMaterialList.pending]: (state) => {
      state.loading = true;
    },
    [supplierMaterialList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.supplierMaterialsList = action?.payload?.data
        // console.log("state.supplierOrdersDetailList",state.supplierOrdersDetailList?.shipments);
      }
    },
    [transporterOrderList.pending]: (state) => {
      state.loading = true;
    },
    [transporterOrderList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.transporterOrdersList = action?.payload?.data
      }
    },
    [driversList.pending]: (state) => {
      state.loading = true;
    },
    [driversList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.driverList = action?.payload?.data
      }
    },
    [vehicleList.pending]: (state) => {
      state.loading = true;
    },
    [vehicleList.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.vehiclesList = action?.payload?.data
      }
    },
    [helpCenter.pending]: (state) => {
      state.loading = true;
    },
    [helpCenter.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.helpCenterList = action?.payload?.data
      }
    },
    [deleteHelpCenter.pending]: (state) => {
      // state.loading = true;
    },
    [deleteHelpCenter.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.helpCenterList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        state.update = new Date()
        console.log("List", List);
      }
    },
    [materialCategory.pending]: (state) => {
      state.loading = true;
    },
    [materialCategory.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        state.materialCategoryList = action?.payload?.data
      }
    },
    [addMaterial.pending]: (state) => {
      // state.loading = true;
    },
    [addMaterial.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.materialList))
        // List.push(action?.payload?.data)
        // state.materialList = List
        // console.log("action.payload?.data",List);
        // List = state.materialList
        state.update = new Date()
      }
    },
    [updateMaterial.pending]: (state) => {
      // state.loading = true;
    },
    [updateMaterial.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.materialList))
        // console.log("action.payload?.data",List);
        state.image = ''
        state.update = new Date()
      }
    },
    [vehicleType.pending]: (state) => {
      // state.loading = true;
    },
    [vehicleType.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        // state.loading = false;
        state.vehicleTypeList = action?.payload?.data
      }
    },
    [tieredPricing.pending]: (state) => {
      state.loading = true;
    },
    [tieredPricing.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.loading = false;
        // state.vehicleTypeList = action?.payload?.data
      }
    },
    [dashboardCount.pending]: (state) => {
      // state.loading = true;
    },
    [dashboardCount.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.dashboardCounts = action?.payload?.data
        // state.loading = false;
        // state.vehicleTypeList = action?.payload?.data
      }
    },
    [chartData.pending]: (state) => {
      // state.loading = true;
    },
    [chartData.fulfilled]: (state, action) => {
      if (action?.payload?.success && action.payload?.data) {
        state.chartDataList = action?.payload?.data
        // state.loading = false;
        // state.vehicleTypeList = action?.payload?.data
      }
    },
    [addEmailTemplate.pending]: (state) => {
      // state.loading = true;
    },
    [addEmailTemplate.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.emailTemplateList))
        // console.log("action.payload?.data",List);
        state.update = new Date()
      }
    },
    [updateEmailTemplate.pending]: (state) => {
      // state.loading = true;
    },
    [updateEmailTemplate.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.emailTemplateList))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [updateNotificationTemplate.pending]: (state) => {
      // state.loading = true;
    },
    [updateNotificationTemplate.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.notificationTemplateList))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [updateInvoice.pending]: (state) => {
      // state.loading = true;
    },
    [updateInvoice.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.orderList))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [sendReceipt.pending]: (state) => {
      // state.loading = true;
    },
    [sendReceipt.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        // const List = JSON.parse(JSON.stringify(state.orderList))
        // console.log("action.payload?.data",List);
        // state.update = new Date()
      }
    },
    [editSupplierMaterial.pending]: (state) => {
      // state.loading = true;
    },
    [editSupplierMaterial.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.supplierMaterialsList))
        // console.log("action.payload?.data",List);
        state.update = new Date()
      }
    },
    [deleteMaterial.pending]: (state) => {
      // state.loading = true;
    },
    [deleteMaterial.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.materialList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        state.update = new Date()
        console.log("List", List);
      }
    },
    [orderCancel.pending]: (state) => {
      // state.loading = true;
    },
    [orderCancel.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        state.update = new Date()
        console.log("List", List);
      }
    },
    [forgotPassword.pending]: (state) => {
      // state.loading = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // state.update = new Date()
        // console.log("List",List);
      }
    },
    [getTermsConditions.pending]: (state) => {
      // state.loading = true;
    },
    [getTermsConditions.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.termsAndConditions = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [updateTermsConditions.pending]: (state) => {
      // state.loading = true;
    },
    [updateTermsConditions.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.termsAndConditions))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [getPrivacyPolicy.pending]: (state) => {
      // state.loading = true;
    },
    [getPrivacyPolicy.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.privacyPolicy = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [updatePrivacyPolicy.pending]: (state) => {
      // state.loading = true;
    },
    [updatePrivacyPolicy.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.privacyPolicy))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [updateAbout.pending]: (state) => {
      // state.loading = true;
    },
    [updateAbout.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        const List = JSON.parse(JSON.stringify(state.about))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [getAbout.pending]: (state) => {
      // state.loading = true;
    },
    [getAbout.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.about = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [invoiceDownload.pending]: (state) => {
      // state.loading = true;
    },
    [invoiceDownload.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.privacyPolicy = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [getTieredPricing.pending]: (state) => {
      // state.loading = true;
    },
    [getTieredPricing.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.tieredPricingList = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [supplierPayments.pending]: (state) => {
      state.loading = true;
    },
    [supplierPayments.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.loading = false;
        state.supplierPaymentsList = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [updateSupplierPayments.pending]: (state) => {
      state.loading = true;
    },
    [updateSupplierPayments.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.loading = false;
        const List = JSON.parse(JSON.stringify(state.supplierPaymentsList))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [transporterPayments.pending]: (state) => {
      state.loading = true;
    },
    [transporterPayments.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.loading = false;
        state.transporterPaymentsList = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [updateTransporterPayments.pending]: (state) => {
      state.loading = true;
    },
    [updateTransporterPayments.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.loading = false;
        const List = JSON.parse(JSON.stringify(state.supplierPaymentsList))
        console.log("action.payload?.data", List);
        state.update = new Date()
      }
    },
    [getLoadAssumptions.pending]: (state) => {
      // state.loading = true;
    },
    [getLoadAssumptions.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        state.loadAssumptions = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [changePassword.pending]: (state) => {
      // state.loading = true;
    },
    [changePassword.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.loading = false;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // state.update = new Date()
        // console.log("List",List);
      }
    },
    [cancelOrder.pending]: (state) => {
      // state.loading = true;
    },
    [cancelOrder.fulfilled]: (state, action) => {
      if (action?.payload?.success) {
        // state.ordersList = action.payload?.data;
        // const List = JSON.parse(JSON.stringify(state.supplierOrdersList))
        // List.splice(0, 1, action?.meta?.arg);
        // console.log("action.payload?.data",action?.meta?.arg);
        // state.updatedTransporter = action?.meta?.arg
        // console.log("List",List);
      }
    },
    [getRubblData.fulfilled]: (state, action) => {
      state.rubblAbout = action.payload?.data;
    },
    [getProductsList.fulfilled]: (state, action) => {

      let lists = [];
      (action.payload.data || []).forEach((product)=> {
        if (product.category_id === null){
          lists.push(product);
        } else {
          const products = (product.products || []).map((ele)=>({...ele,parentName:product.name}))
          lists = [...lists,...products];
        }
      })

      state.productsList = lists;
      state.searchProductsList = lists;
    },
    [createProduct.fulfilled]: (state) => {
      state.isOpenAddProductModal = false;
    },
    [updateProduct.fulfilled]: (state) => {
      state.isOpenAddProductModal = false;
    },
    [toggleAddEditProducts.fulfilled]: (state, action) => {
      state.isOpenAddProductModal = !state.isOpenAddProductModal;
      state.currentProduct = action.payload;
    },
    [searchProducts.fulfilled]: (state, action) => {
      let filteredList = [...state.productsList];

      if (action.payload.category) {
        filteredList = filteredList.filter(product => product.category_id === action.payload.category);
      }
      if (action.payload.productName) {
        filteredList = filteredList.filter(product => product.name.toLowerCase().includes(action.payload.productName.toLowerCase()));
      }
      if (action.payload.subCategory) {
        filteredList = filteredList.filter(product => product.sub_category_id === action.payload.subCategory);
      }

      state.searchProductsList = [...filteredList];
    },
  }
})

export default authSlice.reducer

