import Config from "../config";
import {get, save} from "../utils";
import axios from "axios";
import {toast} from "react-toastify";

let instance = axios.create({
    baseURL: 'http://44.226.238.61/',
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

let instance1 = axios.create({
    baseURL: 'https://api.rubbl.com.au/api/',
    headers: {
        "Content-Type": "application/json",
    },
});

let noLimitInstance = axios.create({
    baseURL: 'https://api.rubbl.com.au/api/',
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

instance1.interceptors.request.use(function (config) {
    const token = get("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});
instance.interceptors.request.use(function (config) {
    const token = get("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});
noLimitInstance.interceptors.request.use(function (config) {
    const token = get("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

const API = {
    getCategory: async () => {
        return instance
            .get(Config.GET_CATEGORY)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    getLaborCharge: async () => {
        return instance1
            .get("/rubbish/labor-charge")
            .then((res) => {
                if (res && res.data) {
                    return res.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    updateLabourCharge: async (data) => {
        return instance1.post("/rubbish/labor-charge",data)
            .then((res) => {
                toast.success("Labour Charges Updated");
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                (err.response?.data?.error || []).forEach((message)=>toast.error(message));
                return err;
            });
    },
    createCategory: async (props) => {
        const {id,...data} =  props;

        let categoryURL = Config.GET_CATEGORY;
        if (id) {
            categoryURL += `/${id}`;
        }
        return new Promise((resolve, reject)=> {
            return noLimitInstance
                .post(categoryURL,data)
                .then((res) => {
                    toast.success(res?.data?.message)
                    if (res && res.data) {
                        resolve(res.data.data)
                        return res.data.data;
                    }
                    return res;
                })
                .catch((err) => {
                    (err.response?.data?.error || []).forEach((message)=>toast.error(message));
                    reject(err);
                    return err;
                });
        })
    },
    deleteCategoryItem:async (item) => {
        return instance
            .delete(`${Config.GET_CATEGORY}/${item.id}`)
            .then((res) => {
                toast.success(res?.data?.message)
                return item.id;
            })
            .catch((err) => {
                (err.response?.data?.error || []).forEach((message)=>toast.error(message));
                return err;
            });
    },
}

export default API