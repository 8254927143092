// export const USER_LOGIN = 'USER_LOGIN'
// export const USER_LOGIN = 'USER_LOGIN';
export const LOGIN = 'LOGIN';
export const ACCOUNTLIST = 'ACCOUNTLIST';
export const SUPPLIER_LIST = 'SUPPLIER_LIST';
export const TRANSPORTER_LIST = 'TRANSPORTER_LIST';
export const ORDERS_LIST = 'ORDERS_LIST';
export const EMAIL_TEMPLATE = 'EMAIL_TEMPLATE';
export const MATERIAL_LIST = 'MATERIAL_LIST';
export const NOTIFICATION_TEMPLATE = 'NOTIFICATION_TEMPLATE';
export const INVOICE_LIST = 'INVOICE_LIST';
export const ADD_CONTRACTOR = 'ADD_CONTRACTOR';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const ADD_TRANSPORTER = 'ADD_TRANSPORTER';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPDATE_CONTRACTOR = 'UPDATE_CONTRACTOR';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const UPDATE_TRANSPORTER= 'UPDATE_TRANSPORTER';
export const ACTIVE_SITES= 'ACTIVE_SITES';
export const CONTRACTOR_ORDER_LIST = 'CONTRACTOR_ORDER_LIST';
export const CONTRACTOR_INVOICE_LIST = 'CONTRACTOR_INVOICE_LIST';
export const SUPPLIER_ORDER_LIST = 'SUPPLIER_ORDER_LIST';
export const SUPPLIER_ORDER_DETAIL = 'SUPPLIER_ORDER_DETAIL';
export const SUPPLIER_MATERIAL_LIST = 'SUPPLIER_MATERIAL_LIST';
export const TRANSPORTER_ORDER_LIST = 'TRANSPORTER_ORDER_LIST';
export const DRIVERS_LIST = 'DRIVERS_LIST';
export const VEHICLE_LIST = 'VEHICLE_LIST';
export const HELP_CENTER = 'HELP_CENTER';
export const DELETE_HELP_CENTER = 'DELETE_HELP_CENTER';
export const MATERIAL_CATEGORY = 'MATERIAL_CATEGORY';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const VEHICLE_TYPE = 'VEHICLE_TYPE';
export const TIERED_PRICING = 'TIERED_PRICING';
export const DASHBOARD_COUNT = 'DASHBOARD_COUNT';
export const CHART_DATA = 'CHART_DATA';
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const EDIT_NOTIFICATION_TEMPLATE = 'UPDATE_NOTIFICATION_TEMPLATE'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const SEND_RECEIPT = 'SEND_RECEIPT'
export const EDIT_SUPPLIER_MATERIAL = 'EDIT_SUPPLIER_MATERIAL'
export const DELETE_MATERIAL = 'DELETE_MATERIAL'
export const ORDER_CANCEL = 'ORDER_CANCEL'
export const FORGOT_PASSWORD= 'FORGOT_PASSWORD'
export const GET_TERMS_CONDITIONS = 'GET_TERMS_CONDITIONS'
export const UPDATE_TERMS_CONDITIONS = 'UPDATE_TERMS_CONDITIONS'
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY'
export const UPDATE_PRIVACY_POLICY = 'UPDATE_PRIVACY_POLICY'
export const GET_ABOUT = 'GET_ABOUT'
export const UPDATE_ABOUT = 'UPDATE_ABOUT'
export const INVOICE_DOWNLOAD = 'INVOICE_DOWNLOAD'
export const GET_TIERED_PRICING = 'GET_TIERED_PRICING'
export const SUPPLIER_PAYMENTS = 'SUPPLIER_PAYMENTS'
export const UPDATE_SUPPLIER_PAYMENTS = 'UPDATE_SUPPLIER_PAYMENTS'
export const TRANSPORTER_PAYMENTS = 'TRANSPORTER_PAYMENTS'
export const UPDATE_TRANSPORTER_PAYMENTS = 'UPDATE_TRANSPORTER_PAYMENTS'
export const GET_LOAD_ASSUMPTIONS = 'GET_LOAD_ASSUMPTIONS'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const TOGGLE_SIDEBAR = 'toggle_sidebar'
export const CREATE_ABOUT = 'create_about'
export const UPDATE_RUBBISH_ABOUT = 'update_rubbish_about'
export const GET_RUBBL_DATA = 'get_rubbl_data'
export const PRODUCTS_LIST = 'PRODUCTS_LIST'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const CREATE_PRODUCTS = 'CREATE_PRODUCTS'
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS'
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS'
export const TOGGLE_ADD_EDIT_PRODUCTS = 'TOGGLE_ADD_EDIT_PRODUCTS'
export const IS_OPEN_ADD_PRODUCT_MODAL = 'IS_OPEN_ADD_PRODUCT_MODAL'
export const GET_RUBBISH_INVOICE = 'GET_RUBBISH_INVOICE'
export const SEARCH_INVOICE = 'SEARCH_INVOICE'
export const CHANGE_RUBBISH_ORDER_INVOICE = 'CHANGE_RUBBISH_ORDER_INVOICE'