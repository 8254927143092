import {createSlice} from "@reduxjs/toolkit";
import {getOrdersLists, orderDetails, searchOrders} from "../actions/orders";

const initialState = {
    orderListData: [],
    orderDetailData: [],
    loading: false
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: {
        [getOrdersLists.pending]: (state, action) => {
            state.loading = true;
        },
        [getOrdersLists.fulfilled]: (state, action) => {
            state.orderListData = action.payload;
            state.loading = false;
        },
        [orderDetails.fulfilled]: (state, action) => {
            state.orderDetailData = action.payload;
            state.loading = false;
        },
        [searchOrders.fulfilled]: (state, action) => {
            let filteredList = [...state.orderListData];

            if (action.payload.orderId) {
                filteredList = filteredList.filter(order => order.order_id.toLowerCase().includes(action.payload.orderId.toLowerCase()));
            }
            state.orderListData = [...filteredList];
        },
    }
})

export default orderSlice.reducer;