import axios from "axios";
import {get} from "../utils";
import Config from "../config";
import {toast} from "react-toastify";

let instance = axios.create({
    baseURL: 'http://44.226.238.61/',
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});
instance.interceptors.request.use(function (config) {
    const token = get("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

const API = {
    getOrders: async () => {
        return instance
            .get(Config.GET_ORDERS)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
    updateOrders: async (data) => {
        return instance
            .post(Config.UPDATE_ORDERS, data)
            .then((res) => {
                toast.success("Order has been updated")
                return res;
            })
            .catch((err) => {
                toast.error("Something went wrong.")
                return err;
            });
    },

    cancelOrders: async (id) => {
        return instance
            .post(`${Config.GET_ORDERS}/${id}/cancel`)
            .then((res) => {
                if (res && res.data) {
                    return res.data.data;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    },
}

export default API